import { Sku } from '@commercelayer/sdk';
import { DatoImage } from '@components/image/DatoImage';
import { Price } from '@components/price/Price';
import { ProductFragment } from '@graphql/generated';
import { useAppContext } from '@lib/context/appContext';
import t, { tt } from '@lib/locales';
import { trackProductSelectView } from '@lib/utils/gtmUtils';
import { subPaths } from '@lib/utils/pathUtils';
import { Locales } from '@model/common';
import { ProductAndBundleDataType } from '@model/product';
import { checkIfPossibleAddtoCart } from '@utils/cart-utils';
import { formatPrice } from '@utils/index';
import { productToProductData } from '@utils/product-utils';
import { useEffect, useRef, useState } from 'react';

type Props = {
  locale: Locales;
  baseUrl: string;
  product: ProductFragment;
  sku: Sku;
  index?: number;
  addToCart: (productData: ProductAndBundleDataType) => void;
  onVisible: (productData: ProductAndBundleDataType) => void;
  itemListName: string;
};

export const ProductBox: React.FC<Props> = ({
  locale,
  baseUrl,
  product,
  sku,
  addToCart,
  onVisible,
  index,
  itemListName,
}) => {
  const { name, images, skuCode, slug } = product;
  if (images.length == 0) {
    // empty image
    images.push({
      __typename: 'FileField',
      responsiveImage: {
        __typename: 'ResponsiveImage',
        src: 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=',
        srcSet: '',
        width: 1,
        height: 1,
        alt: '',
        title: '',
      },
    });
  }
  const image = images[0].responsiveImage;
  const [data, setData] = useState<ProductAndBundleDataType | null>(null);
  const [loading, setLoading] = useState(true);
  const [trackingDone, setTrackingDone] = useState(false);
  const { state } = useAppContext() as { state: any };
  const myRef = useRef<HTMLInputElement>(null);
  const url = `${baseUrl}/${subPaths.products[locale]}/${slug}`;

  useEffect(() => {
    if (sku) {
      setLoading(true);
      setData(productToProductData(skuCode, sku, image, url, name));
      setLoading(false);
    }
  }, [sku]);

  if (!data && !product) {
    return null;
  }

  // Track product list view (onScroll)
  useEffect(() => {
    if (!data) {
      return;
    }
    let observer: IntersectionObserver | null = null;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting && !trackingDone) {
        onVisible(data);
        setTrackingDone(true);
        observer?.unobserve(entries[0].target); // Smetti di osservare l'elemento dopo la prima intersezione
      }
    };
    const el = myRef.current;
    const options = {
      threshold: 0.8,
    };

    if (el) {
      observer = new IntersectionObserver(handleIntersection, options);
      observer.observe(el);
    }

    return () => {
      if (observer && el) {
        observer.unobserve(el); // Rimuovi l'observer quando il componente viene smontato
        observer = null;
      }
    };
  }, [trackingDone, myRef, data, onVisible]);

  const isPossibileAddToCart = checkIfPossibleAddtoCart(state, data?.maxQuantity, product.skuCode);

  return (
    <div className="product-box" ref={myRef}>
      <div className="product-box__header">
        <a
          href={url}
          onClick={() => {
            const item = {
              item_id: product.skuCode,
              item_name: product.name,
              index: index,
              item_list_name: itemListName,
              price: data?.price.amount_float,
            };
            trackProductSelectView(item, data?.price?.currency_code);
          }}
        >
          <div className="product-box__title">{name}</div>
          <div className="product-box__image">
            <DatoImage data={image} />
          </div>
        </a>
      </div>
      <div className="product-box__body">
        {loading && <div className="product-loading">{t(locale, 'loading')}</div>}

        {data?.price && (
          <>
            <div className="product-box__price">
              <Price
                {...formatPrice(
                  (data.price.amount_float || 0) + (data.option?.price_amount_float || 0),
                  data.price.currency_code || 'EUR',
                  locale
                )}
              />
            </div>
            {data.available ? (
              <>
                <div className="product-box__action">
                  <button
                    className="button--primary"
                    onClick={() => {
                      addToCart(data);
                    }}
                    disabled={!isPossibileAddToCart}
                  >
                    {t(locale, 'add_to_cart')}
                  </button>
                  {!isPossibileAddToCart && (
                    <div className="product-hero__quantity">
                      {tt(locale, 'maximum_elements_added', {
                        n: data?.maxQuantity,
                        p: t(
                          locale,
                          data?.maxQuantity === 1 ? 'single_product_basepath' : 'product_basepath'
                        ),
                      })}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="product-box__notavailable">{t(locale, 'unavailable_message')}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
